<template>
    <bread-pages>
        <div class="main" v-loading="loading">
            <div class="title">{{ info.name }}</div>
            <div class="content">
                <div class="sub-title">著录项</div>
                <div class="info">
                    <div class="info-item">
                        <span
                            class="text1"
                            v-if="
                                info.category == 'DECLASSIFICATION' ||
                                info.category == 'GENERAL' ||
                                info.category == 'PRODUCT' ||
                                info.category == 'TECHNOLOGY'
                            "
                            >所属领域：</span
                        >
                        <span class="text1" v-if="info.category == 'LOGO'">所属分类：</span>
                        <span class="text1" v-if="info.category == 'COPYRIGHT'">作品类型：</span>
                        <span class="text1" v-if="info.category == 'SOFT_COPYRIGHT'">软著类型：</span>
                        <span class="text2" v-if="info.setting">{{ info.setting.name }}</span>
                    </div>
                    <div class="info-item" v-if="info.patentType">
                        <span class="text1">专利类型：</span>
                        <span class="text2">{{ getLabelName(info.patentType, patentTypeOptionsOne) }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">交易方式：</span>
                        <span class="text2">{{ mode }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">成交价格：</span>
                        <span class="text2">{{ info.soldPrice }}万元 </span>
                    </div>
                    <div class="info-item">
                        <span class="text1">转化来源：</span>
                        <span class="text2">{{ info.soldAddress }}</span>
                    </div>

                    <div class="info-item">
                        <span class="text1">成交时间：</span>
                        <span class="text2">{{ info.soldAt }}</span>
                    </div>
                </div>
                <div class="sub-title">描述</div>
                <div class="text" v-html="info.manual"></div>
                <div class="sub-title">成交案例附图</div>
                <div class="text imgs">
                    <el-image :src="info.img" fit="fill" :preview-src-list="[info.img]"></el-image>
                </div>
            </div>
        </div>
    </bread-pages>
</template>

<script>
import BreadPage from '../../components/page/BreadPage.vue';
import BreadPages from '../../components/page/BreadPages.vue';
import { mode3Options, caseType2Options, patentTypeOptionsOne } from '../../utils/variables';
import common from '../../mixins/common';
export default {
    mixins: [common],
    data() {
        return {
            info: {},
            mode3Options,
            patentTypeOptionsOne,
            loading: true
        };
    },
    components: {
        BreadPage,
        BreadPages
    },
    computed: {
        mode() {
            let info = this.mode3Options.find(item => {
                return item.value === this.info.mode;
            });
            return info ? info.label : '';
        }
    },
    mounted() {
        this.loading = true;
        this.$http.get('/successCase/get/' + this.$route.query.id).then(res => {
            this.info = res;
            setTimeout(() => {
                this.loading = false;
            }, 500);
            let info = caseType2Options.find(item => {
                return item.value === res.category;
            });
            this.$EventBus.$emit('changePreTitle', {
                name: '成交案例' + info.label,
                path: info.path || '/successCases',
                query: {
                    category: res.category
                }
            });
            this.$EventBus.$emit('changeTitle', info.label + '详情');
            document.title = info.label + '详情';
        });
    },
    // SuccessCase
    created() {
        let addView = {
            id: this.$route.query.id,
            type: 'SuccessCase'
        };
        // console.log(addView);
        this.loading = true;
        this.$http.get('/all/addView/', addView).then(res => {
            console.log(res);
        });
    }
};
</script>

<style lang="less" scoped>
.main {
    background-color: #fff;
    padding: 0 30px 150px;
    position: relative;
}
// /deep/ .el-image {
//     width: 200px;
//     height: 300px;
//     margin: 0 auto !important;
// }
.title {
    font-size: 24px;
    font-weight: bold;
    color: @warn;
    line-height: 34px;
    text-align: center;
    padding: 50px 0 20px;
    border-bottom: 1px solid @bg;
    margin: 0 70px;
    border-bottom: 1px solid @bg;
}
.appContainer {
    background: #ffffff !important;
}
.sub-title {
    font-size: 16px;
    font-weight: bold;
    color: @warn;
    line-height: 22px;
    padding: 50px 0 16px;
    .flex();
    &::before {
        content: '';
        width: 5px;
        height: 16px;
        background: @warn;
        border-radius: 3px;
        margin-right: 10px;
    }
}

.info {
    .flex();
    flex-wrap: wrap;
    .info-item {
        width: 45%;
        padding-right: 50px;
        box-sizing: border-box;
        line-height: 32px;
        margin-bottom: 8px;
        .flex();

        .text1 {
            font-size: 14px;
            color: #999999;
            display: inline-block;
            min-width: 72px;
            flex-shrink: 0;
        }

        .text2 {
            font-size: 14px;
            color: #000;
            margin-left: 6px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:nth-child(2n) {
            .text1 {
                min-width: 98px;
            }
        }
    }
}

.text {
    font-size: 14px;
    color: #000000;
    line-height: 20px;

    &.imgs {
        // .flex-col();
        // align-items: center;
        max-width: 300px;
        max-height: 300px;
        margin: 0 auto;
    }
}

.btn {
    .flex();
    justify-content: center;
    position: absolute;
    right: 60px;
    top: 120px;
    .icon-button {
        line-height: 32px;
    }
}
</style>
